import Vue from 'vue'
import App from './main'
import VueRouter from 'vue-router'
import routes from './js/route'
import ZGManager from './js/api'
import Dayjs from 'dayjs'
import { localStorage, RandomCode } from '@/js/Utils'
import './js/filter'
import 'default-passive-events'
import * as echarts from 'echarts/lib/echarts'
import CompConfigs from '@/websites/cms/js/template/CompConfigs'
var allecharts = require('echarts');

Vue.prototype.$ZGManager = ZGManager
Vue.prototype.$EventBus = new Vue()
Vue.prototype.$ELEMENT = {size: 'medium'}
Vue.prototype.$localStorage = localStorage
Vue.prototype.$RandomCode = RandomCode
Vue.prototype.$dayjs = Dayjs
Vue.prototype.$allecharts = allecharts
Vue.prototype.$echarts = echarts
Vue.prototype.$configs = CompConfigs
Vue.config.productionTip = false
Vue.config.devtools = true
Vue.use(VueRouter)

const router = new VueRouter({
  routes
})

var vum = new Vue({
  router,
  render: h => h(App),
}).$mount('#app');

Vue.prototype.$winRedirectPage = function winRedirectPage(type, url) {
  // 原生跳转链接,type为0,在当前页面跳转,type为1,打开新的标签页
  if(type){
    window.open(url);
  }else{
    window.location.href = url;
  }
}