import axios from "axios";
const _axios = axios.create({ timeout: 15000 });
import login from "./login";

let loadingServer;
class ZGManager {
  constructor() {
    _axios.interceptors.request.use(req => {
      loadingServer = Vue.prototype.$loading({
        lock: true,
        text: '努力加载中···',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.3)',
        customClass: 'api-loading'
      });
      return req;
    }, err => {
      loadingServer.close();
      return err;
    });
    _axios.interceptors.response.use(res => {
      loadingServer.close();
      return res;
    }, err => {
      switch(err.response.status){
        case 401:
          login.show();
          break;
        case 404:
          Vue.prototype.$message.error("服务404，请稍后再试");
          break;
        default:
          Vue.prototype.$message.error(err.response.data.message || err.response.data);
      }
      loadingServer.close();
      return false;
    });
  }

  /** 
   * 上传二进制数据
   */
  async uploadFile(data){
    return _axios.post(`/api/upload/file`, data);
  }
  
  /** 
   * 账户登录
   */
  async userLogin(name, pwd){
    return _axios.post(`/tkcms/login`, {
      loginName: name,
      password: pwd
    });
  }

  
/** 
   * 获取我的个人信息
   */
  async getMyInfo(){
    return _axios.get(`/tkcms/org/members/current`);
  }

  
  /** 
   * 获取我的企业信息
   */
  async getMyCompany(){
    return _axios.get(`/tkcms/org/companies/current`);
  }

  /** 
   * 更新企业信息
   * @param {string} id         企业ID
   * @param {string} shortName  企业简称
   * @param {string} logo       企业LOGO
   * @param {string} name       企业名称
   * @param {string} address    企业地址
   */
  async updateMyCompany(id, params){
    return _axios.put(`/tkcms/org/companies/${id}`, params);
  }

  /** 
   * 更新账户密码
   * @param {string} oldPassword  企业ID
   * @param {string} newPassword  企业简称
   */
  async updateMyPassword(oldPassword, newPassword){
    return _axios.put(`/tkcms/sec/accounts/change/password`, {
      oldPassword: oldPassword,
      newPassword: newPassword
    });
  }

  /** 
   * 查询个性化定制信息
   * @param {string} type       定制类型 1：电子名片；2：商城 ; 3： 微官网
   */
  async getcmstmzConfigs(type = 1) {
    return _axios.get(`/tkcms/cstmz/record/current/${type}`);
  }

  /** 
   * 增加企业名片定制
   * @param {string} name       名片信息
   * @param {string} remark     名片备注
   * @param {string} configs    名片配置
   */
  async createCardConfigs(name = "系统名片创建", remark = "", configs = "{}"){
    return _axios.post(`/tkcms/cstmz/record/card`, {
      name: name,
      remark: remark,
      configs: configs
    });
  }

  /** 
   * 根据ID修改企业名片定制
   * @param {number} id         名片ID
   * @param {number} name       名片名称
   * @param {number} remark     名片备注
   * @param {number} configs    名片配置
   */
  async updateCardConfigs(id, params){
    return _axios.put(`/tkcms/cstmz/record/card/${id}`, params);
  }

  /** 
   * 增加企业商城定制
   * @param {string} name       商城名称
   * @param {string} remark     商城备注
   * @param {string} configs    商城配置
   */
  async createShopConfigs(name = "系统名片创建", remark = "", configs = "{}"){
    return _axios.post(`/tkcms/cstmz/record/micromall`, {
      name: name,
      remark: remark,
      configs: configs
    });
  }

  /** 
   * 根据ID修改企业商城定制
   * @param {number} id         商城ID
   * @param {number} name       商城名称
   * @param {number} remark     商城备注
   * @param {number} configs    商城配置
   */
  async updateShopConfigs(id, params){
    return _axios.put(`/tkcms/cstmz/record/micromall/${id}`, params);
  }

  /** 
   * 增加企业微官网定制
   * @param {string} name       微官网信息
   * @param {string} remark     微官网备注
   * @param {string} configs    微官网配置
   */
  async createSiteConfigs(name = "系统微官网创建", remark = "", configs = "{}"){
    return _axios.post(`/tkcms/cstmz/record/microsite`, {
      name: name,
      remark: remark,
      configs: configs
    });
  }

  /** 
   * 根据ID修改企业微官网定制
   * @param {number} id         名片ID
   * @param {number} name       名片名称
   * @param {number} remark     名片备注
   * @param {number} configs    名片配置
   */
  async updateSiteConfigs(id, params){
    return _axios.put(`/tkcms/cstmz/record/microsite/${id}`, params);
  }

  /** 
   * 获取文章列表
   * @param {string} title          文章标题
   * @param {number} status         文章状态
   * @param {number} type           类型，0文章，1视频
   * @param {Boolean}isStaffProvide 内容是否员工提供（内容标识 0系统采集内容；1企业员工采集内容）   
   * @param {number} pageIndex      页码
   * @param {number} pageNumber     每页条数
   */
  async getArticleList(params){
    return _axios.get(`/tkcms/content/all/list`, { params });
  }

  /** 
   * 获取文章内容
   * @param {string} id             文章标题
   */
  async getArticleContent(id){
    return _axios.get(`/tkcms/content/${id}`);
  }

  /** 
   * 修改文章
   * @param {string} id             文章ID
   * @param {string} title          文章标题
   * @param {string} logo           文章封面
   * @param {string} status         文章状态
   * @param {object} configs        文章的JSON配置
   */
  async updateArticle(id, params){
    return _axios.put(`/tkcms/content/${id}`, params);
  }

  /** 
   * 创建文章
   * @param {string} title          文章标题
   * @param {string} logo           文章封面
   * @param {string} status         文章状态
   * @param {object} configs        文章的JSON配置
   */
  async createArticle(params){
    return _axios.post(`/tkcms/content/edit`, params);
  }

  /** 
   * 采集文章/视频
   * @param {string} flag           内容标识。0模板内容，1用户内容，2企业内容
   * @param {string} type           类型。0文章；1视频；
   * @param {string} url            原始文章地址
   * @param {string} column         视频类型时有效。100000：精选；100001：热门
   */
  async pickupArticle(flag, type, url, column){
    return _axios.post(`/tkcms/proxy/ms/tk/content/pickup`, {
      flag: flag,
      type: type,
      url: url,
      column: column
    });
  }

  /** 
   * 文章批量发布/下架
   * @param {string} ids            文章ID，多个ID以,分隔
   * @param {string} status         文章状态 -1：删除； 0：下架；1：隐私 只有自己可见（未发布）；2：公开（已发布）
   */
  async updateArticleStatus(ids, status){
    return _axios.post(`/tkcms/content/batch/set`, {
      ids: ids,
      status: status
    });
  }

  /** 
   * 我的团队列表
   */
  async getMyTeams(){
    return _axios.get(`/tkcms/org/teams/mine/list`);
  }

  /** 
   * 创建团队
   * @param {string} name           团队名称
   */
  async createTeam(name){
    return _axios.post(`/tkcms/org/teams`, { name: name });
  }

  /** 
   * 更新团队
   * @param {string} id             团队ID
   * @param {string} name           团队名称
   * @param {number} status         团队状态 -1：删除；0：禁用；1：启用
   */
  async updateTeam(id, params){
    return _axios.put(`/tkcms/org/teams/${id}`, params);
  }

  /** 
   * 删除团队
   * @param {string} id             团队ID
   */
  async deleteTeam(id){
    return _axios.delete(`/tkcms/org/teams/${id}`);
  }

  /** 
   * 团队成员列表
   * @param {string} id             团队ID
   * @param {string} status         成员状态
   */
  async getTeamMember(id, status){
    let params = {};
    if(id != 0) params.teamId = id;
    if(status != '0+') params.status = status;
    return _axios.get(`/tkcms/org/all/members`, { params });
  }

  /** 
   * 团队成员详情
   * @param {string} id             成员ID
   */
  async getTeamMemberInfo(id){
    return _axios.get(`/tkcms/org/members/${id}`);
  }

  /** 
   * 更新成员信息
   * @param {string} id             成员ID
   * @param {string} teamId         团队ID
   * @param {string} name           成员姓名
   * @param {string} supervisor     是否主管
   * @param {object} status         状态
   */
  async updateMemberInfo(id, params){
    return _axios.put(`/tkcms/org/members/${id}`, params);
  }

  /** 
   * 角色列表
   */
  async getRoleList(){
    return _axios.get(`/tkcms/sec/roles`);
  }

  /** 
   * 查询指定角色信息
   * @param {string} id             成员ID
   */
  async getRole(id){
    return _axios.get(`/tkcms/sec/roles/${id}`,);
  }

  /** 
   * 创建角色
   * @param {string} name           必须，角色名称
   * @param {string} desc           必须，角色描述
   * @param {Array}  resourceIds    必须，资源ID
   */
  async createRole(params){
    return _axios.post(`/tkcms/sec/roles`, params);
  }

  /** 
   * 更新角色
   * @param {string} name           必须，角色ID
   * @param {string} name           必须，角色名称
   * @param {string} desc           必须，角色描述
   * @param {string} status         必须，角色状态
   * @param {Array}  resourceIds    必须，资源ID
   */
  async updateRole(id, params){
    return _axios.put(`/tkcms/sec/roles/${id}`, params);
  }

  /** 
   * 删除角色
   * @param {string} id             角色ID
   */
  async delRole(id){
    return _axios.delete(`/tkcms/sec/roles/${id}`,);
  }

  /** 
   * 根据角色ID获取成员列表
   * @param {string} id             成员ID
   * @param {number} count          count=1时只返回数量
   */
  async getMembersByRole(id, count = 0){
    return _axios.get(`/tkcms/org/members/by/role/${id}?count=${count}`);
  }

  /** 
   * 获取资源列表
   * @param {string} type           资源类型。0：菜单资源；1：URL资源；2：事件资源
   */
  async getResource(type){
    return _axios.get(`/tkcms/sec/resources`, {
      params: {
        type: type
      }
    });
  }

  /** 
   * 根据角色ID获取角色所拥有的的资源列表
   * @param {string} roleId         角色ID
   * @param {string} type           资源类型。0：菜单资源；1：URL资源；2：事件资源
   */
  async getRoleResource(roleId, type = 0){
    return _axios.get(`/tkcms/sec/resources/by/role/${roleId}?type=${type}`);
  }

  /** 
   * 分页查询文件夹
   * @param {string} name           文件夹名称
   * @param {string} status         文件夹状态 0：禁用；1：启用
   * @param {number} pageIndex      页码
   * @param {number} pageNumber     每页条数
   */
  async getFolder(params){
    return _axios.get(`/tkcms/material/folder/page/list`, { params });
  }

  /** 
   * 查询所有文件夹
   */
  async getAllFolder(params){
    return _axios.get(`/tkcms/material/folder/all/list`);
  }

  /** 
   * 新建文件夹
   * @param {string} name           文件夹名称
   * @param {string} remark         文件夹备注
   */
  async createFolder(name, remark = ""){
    return _axios.post(`/tkcms/material/folder/`, { 
      name: name,
      remark: remark
    });
  }

  /** 
   * 设置文件夹状态
   * @param {string} id             文件夹ID
   * @param {string} status         文件夹状态
   */
  async updateFolderStatus(id, status){
    return _axios.put(`/tkcms/material/folder/status/${id}`, {
      status: status
    });
  }

  /** 
   * 根据ID修改文件夹
   * @param {string} id             文件夹ID
   * @param {string} name           文件夹名称(长度2~15，不允许重复)
   * @param {string} remark         文件夹备注
   */
  async updateFolder(id, params){
    return _axios.put(`/tkcms/material/folder/${id}`, params);
  }

  /** 
   * 查询素材列表
   * @param {string} folderId       文件夹ID
   * @param {string} title          文件标题
   * @param {string} status         文件状态
   * @param {string} type           文件类型 1图片；2联系人；3链接；4二维码；5商品；6营销活动;7广告中心;8文档;9视频	
   * @param {number} pageIndex      页码
   * @param {number} pageNumber     每页条数
   */
  async getMaterialList(params){
    return _axios.get(`/tkcms/material/page/list`, { params });
  }

  /** 
   * 根据ID查询素材详情
   * @param {string} id             素材ID
   */
  async getMaterial(id){
    return _axios.get(`/tkcms/material/${id}`);
  }

  /** 
   * 新增素材
   * @param {string} title          文件名称
   * @param {string} configs        文件配置信息
   * @param {string} folderId       文件夹ID
   * @param {string} type           文件类型
   */
  async addMaterial(params){
    return _axios.post(`/tkcms/material/`, params);
  }

  /** 
   * 设置素材状态
   * @param {string} id             文件ID
   * @param {string} status         文件状态
   */
  async updateMaterialStatus(id, status){
    return _axios.put(`/tkcms/material/status/${id}`, {
      status: status
    });
  }
  
  /** 
   * 根据ID修改素材
   * @param {string} id             文件ID
   * @param {string} title          文件名称(长度2~15，不允许重复)
   * @param {string} type           文件类型
   * @param {string} folderId       文件夹ID
   * @param {string} configs        文件配置信息
   */
  async updateMaterial(id, params){
    return _axios.put(`/tkcms/material/${id}`, params);
  }

  /** 
   * 获取第一级分类列表
   * @param {string} status         类目状态
   */
  async getParentSortList(status = ''){
    return _axios.get(`/tkcms/shop/subcategory/list`, {
      params: {
        status: status
      }
    });
  }

  /** 
   * 获取第二级分类列表
   * @param {string} id             父类ID
   * @param {string} status         类目状态
   */
  async getChildrenSortList(id, status = ''){
    return _axios.get(`/tkcms/shop/subcategory/subList`, {
      params: {
        id: id,
        status: status
      }
    });
  }

  /** 
   * 获取商品分类列表
   * @param {string} status         分类状态
   * @param {string} companyId      公司ID
   */
  async getSortList(status, companyId){
    return _axios.get(`/tkcms/shop/subcategory/list`, {
      params: {
        status: status,
        companyId: companyId
      }
    });
  }

  /** 
   * 创建分类
   * @param {string} name           分类名称
   * @param {string} images         分类图标
   * @param {string} parentId       上级分类，如需创建父类，则传0
   * @param {string} weight         分类权重
   * @param {string} status         分类状态。-1：删除，1：启用
   */
  async createSort(params){
    return _axios.post(`/tkcms/shop/subcategory`, params);
  }

  /** 
   * 更新分类
   * @param {string} name           分类名称
   * @param {string} images         分类图标
   * @param {string} parentId       上级分类，如需创建父类，则传0
   * @param {string} weight         分类权重
   * @param {string} status         分类状态。-1：删除，1：启用
   */
  async updateSort(id, params){
    return _axios.put(`/tkcms/shop/subcategory/${id}`, params);
  }

  /** 
   * 删除分类
   * @param {string} id             分类ID
   */
  async deleteSort(id){
    return _axios.delete(`/tkcms/shop/subcategory/${id}`);
  }

  /** 
   * 调整分类顺序
   * @param {string} id             分类ID
   * @param {string} offset         偏移的大小（可以传入负值）
   */
  async offsetSort(id, offset){
    return _axios.get(`/tkcms/shop/subcategorySort`, {
      params: {
        id: id,
        offset: offset
      }
    });
  }

  /** 
   * 获取商品列表
   * @param {string} status         -1：删除；0：禁用；1：启用'
   * @param {string} type           商品类型,1默认,2:实物-展示,3:实物-支付,4:虚拟
   * @param {string} categoryId     商品分类
   * @param {string} companyId      商品分类
   */
  async getGoodsList(params){
    return _axios.get(`/tkcms/shop/page/list`, {params: params});
  }

  /** 
   * 获取商品详情
   * @param {string} id             商品id
   */
  async getGoodsDesc(id){
    return _axios.get(`/tkcms/shop/products/${id}`);
  }

  /** 
   * 创建商品
   * @param {string} name           商品名称
   * @param {string} thumbnail      商品缩略图
   * @param {string} images         商品细节图。多图以JSON数组存储
   * @param {string} description    商品描述
   * @param {string} categoryId     商品分类
   */
  async createGoods(params){
    return _axios.post(`/tkcms/shop/products`, params);
  }

  /** 
   * 更新商品
   * @param {string} id             商品id
   * @param {string} name           商品名称
   * @param {string} thumbnail      商品缩略图
   * @param {string} images         商品细节图。多图以JSON数组存储
   * @param {string} description    商品描述
   * @param {string} categoryId     商品分类
   */
  async updateGoods(id, params){
    return _axios.put(`/tkcms/shop/products/${id}`, params);
  }

  /** 
   * 删除商品
   * @param {string} id             商品id
   */
  async deleteGoods(id){
    return _axios.delete(`/tkcms/shop/products/${id}`);
  }

  /** 
   * 获取订单列表
   * @param {string} businessId     业务ID
   * @param {string} companyId      企业ID
   */
  async getOrderList(companyId, businessId = 'stmTRn'){
    return _axios.get(`/tkcms/shop/order/list`, {
      companyId: companyId,
      businessId: businessId
    });
  }

  /** 
   * 获取指定订单物流列表
   * @param {string} orderNo        订单号
   */
  async getOrderLogistics(orderNo){
    return _axios.get(`/tkcms/shop/logistics/${orderNo}`);
  }

  /** 
   * 用户行为分时段统计（主要用于柱状图、折线图等）
   * @param {string} unit           h/d/m，时间分区单位。按小时/按日/按月
   * @param {string} type           trace或者用户user
   * @param {string} cid            公司ID
   * @param {string} tid            团队ID
   * @param {string} xid            销售ID	
   * @param {string} utk            用户标识
   * @param {string} rt             资源类型
   * @param {string} rid            资源ID
   * @param {string} evt            事件标识
   * @param {array}  ranges         统计区间
   * @param {string} ranges[i].key  统计标识
   * @param {string} ranges[i].from 开始时间。YYYY-MM-DD
   * @param {string} ranges[i].to   结束时间。YYYY-MM-DD
   */
  async statHistograms(params, unit = "d", type = "trace"){
    return _axios.post(`/tkcms/stat/${type}/histograms/${unit}`, params);
  }

  /** 
   * 行为分区统计
   * @param {string} type           trace或者用户user
   * @param {string} groupby        分组，evt或者cid等
   * @param {string} cid            公司ID
   * @param {string} tid            团队ID
   * @param {string} utk            用户标识
   * @param {string} rt             资源类型
   * @param {string} rid            资源ID
   * @param {array}  ranges         统计区间
   * @param {string} ranges[i].key  统计标识
   * @param {string} ranges[i].from 开始时间。YYYY-MM-DD
   * @param {string} ranges[i].to   结束时间。YYYY-MM-DD
   */
  async statRanges(params, type = "trace", groupby = "evt"){
    return _axios.post(`/tkcms/stat/${type}/ranges/${groupby}`, params);
  }

  /** 
   * 用户行为分布统计
   * @param {string} type           trace或者用户user
   * @param {string} groupby        分组字段。rt：按资源类型统计；evt：按行为统计；geoip.region_name：按地区统计
   * @param {string} cid            公司ID
   * @param {string} tid            团队ID
   * @param {string} utk            用户标识
   * @param {string} rt             资源类型
   * @param {string} rid            资源ID
   * @param {array}  ranges         统计区间
   * @param {string} ranges[i].key  统计标识
   * @param {string} ranges[i].from 开始时间。YYYY-MM-DD
   * @param {string} ranges[i].to   结束时间。YYYY-MM-DD
   */
  async statDistribution(params, type = "trace", groupby = "evt"){
    return _axios.post(`/tkcms/stat/${type}/distribution/${groupby}`, params);
  }

  /** 
   * 销售推广统计
   * @param {string} cid            公司ID
   * @param {string} tid            团队ID
   * @param {string} rt             资源类型
   * @param {string} evt            事件类型
   * @param {array}  ranges         统计区间
   * @param {string} ranges[i].key  统计标识
   * @param {string} ranges[i].from 开始时间。YYYY-MM-DD
   * @param {string} ranges[i].to   结束时间。YYYY-MM-DD
   */
  async statSellerSpread(params){
    return _axios.post(`/tkcms/stat/seller/spread`, params);
  }

  /** 
   * 公司客户列表
   * @param {string} size           分页大小
   * @param {string} after          上页结果中的对应值。不传则返回第一页
   * @param {string} cid            公司ID
   * @param {string} xid            销售ID
   * @param {string} tid            团队ID
   * @param {string} rt             资源类型
   * @param {string} evt            事件类型
   * @param {array}  ranges         统计区间
   * @param {string} ranges[i].key  统计标识
   * @param {string} ranges[i].from 开始时间。YYYY-MM-DD
   * @param {string} ranges[i].to   结束时间。YYYY-MM-DD
   */
  async statCustomerList(params, query){
    return _axios.post(`/tkcms/stat/customer/list`, params, {params: query});
  }

  /** 
   * 获取销售名字
   * @param {array}  xid           分页大小
   */
  async getStaffName(xid){
    return _axios.post(`/tkcms/org/member/name`, {
      xid: xid
    });
  }

  /** 
   * 公司客户列表
   * @param {string} utk            客户utk
   * @param {string} xid            销售id
   */
  async changeCustomerStaff(utk, xid){
    return _axios.put(`/tkcms/customer/translate`, {
      utk: utk,
      xid: xid
    });
  }

  /** 
   * 客户行为列表
   * @param {string} size           分页大小
   * @param {string} after          上页结果中的对应值。不传则返回第一页
   * @param {string} cid            公司ID
   * @param {string} tid            团队ID
   * @param {string} rt             资源类型{name: '名片', val: 'card'},  {name: '文章', val: 'article'},  {name: '官网', val: 'site'},  {name: '视频', val: 'video'}
   * @param {string} evt            事件类型
   * @param {array}  ranges         统计区间
   * @param {string} ranges[i].key  统计标识
   * @param {string} ranges[i].from 开始时间。YYYY-MM-DD
   * @param {string} ranges[i].to   结束时间。YYYY-MM-DD
   */
  async statCustomerEvents(params, query){
    return _axios.post(`/tkcms/stat/customer/events`, params, {params: query});
  }

  /** 
   * 查询任务列表
   * @param {string} name           任务名称
   * @param {string} status         任务状态。0停用，1启用，不传为全部
   * @param {string} pageIndex      当前页码
   * @param {string} pageNumber     查询条数
   */
  async getTaskList(params){
    return _axios.get(`/tkcms/task/page/list`, {
      params: params
    });
  }

  /** 
   * 创建任务
   * @param {string} type           trace或者用户user
   * @param {string} groupby        分组，evt或者cid等
   * @param {string} cid            公司ID
   * @param {string} tid            团队ID
   * @param {string} utk            用户标识
   * @param {string} rt             资源类型
   * @param {string} rid            资源ID
   * @param {array}  ranges         统计区间
   * @param {string} ranges[i].key  统计标识
   * @param {string} ranges[i].from 开始时间。YYYY-MM-DD
   * @param {string} ranges[i].to   结束时间。YYYY-MM-DD
   */
  async creatTask(params){
    return _axios.post(`/tkcms/task/create`, params);
  }

  /** 
   * 查询任务详情
   * @param {string} id             任务ID
   */
  async getTaskDetail(id){
    return _axios.get(`/tkcms/task/info/get`, {
      params: {
        id: id
      }
    });
  }

  /** 
   * 更新任务
   * @param {string} id             任务ID
   * @param {string} params         任务数据
   */
  async updateTask(id, params){
    return _axios.put(`/tkcms/task/update/${id}`, params);
  }

  /** 
   * 删除任务
   * @param {string} id             任务ID
   */
  async deleteTask(id){
    return _axios.delete(`/tkcms/task/delete/${id}`);
  }

  /** 
   * 获取任务统计图表
   * @param {string} id             任务ID
   * @param {string} startTime      开始时间
   * @param {string} endTime        结束时间
   */
  async getTaskStat(id, startTime, endTime){
    return _axios.get(`/tkcms/task/recent/data`, {
      params: {
        id: id,
        startTime: startTime,
        endTime: endTime
      }
    });
  }

  /** 
   * 获取任务统计列表
   * @param {string} id             任务ID
   * @param {string} startTime      开始时间
   * @param {string} endTime        结束时间
   * @param {string} name           结束时间
   */
  async getTaskStatList(id, params){
    return _axios.get(`/tkcms/task/singal/view`, {
      params: {
        id: id,
        ...params
      }
    });
  }
  /**
   * 批量修改海报状态
   * @param {*} xid                 id组  
   * @param {*} status              状态
   */
async updataPosterStatus(ids,status) {
  return _axios.post(`/tkcms/material/buck/status`,{
    xid: ids,
    status: status
  })
}
  /** 
   * 获取海报分类列表
   * @param {string} createdAt      创建时间 
   * @param {string} count          海报数量 
   * @param {string} name           海报名称 
   * @param {string} type           类型
  */
  async materialCategoryList(params) {
    return _axios.get(`/tkcms/material/categorys/list`,{params})
  }
  // async posterCategoryList() {
  //   return _axios.get(`/tkcms/material/categorys/list`)
  // }
  /** 
   * 海报新增分类
   * @param {string} createdAt      创建时间 
   * @param {string} count          海报数量 
   * @param {string} name           海报名称 
   * @param {string} type           51类型 
  */
 async posterCategoryAdd(params) {
  return _axios.post(`/tkcms/material/category/add`,params)
}

  /** 
   * 海报分类删除上架下架分类
   * @param {string} id             海报分类id 
  */
async posterCategoryEdit(id,params) {
  return _axios.put(`/tkcms/material/category/${id}`,params)
}
/** 
 * 批量修改海报分类状态删除：-1；上架：1；下架：0
 * /tkcms/material/category/status
 * @param {string} xid             海报分类id组
 * @param {string} status          海报分类状态
 * 
*/
async updataPosterCategoryStatus(ids,status) {
  return _axios.post(`/tkcms/material/category/status`, {
    xid:ids,
    status:status
  })
}
// /api/org/card/list 名片广场列表
/**
 * 
 * @param {string} province        省份
 * @param {string} city            城市
 * @param {string} district        区
 * @param {string} industryTag     行业 例医疗行业
 * @param {string} pageIndex       页码
 * @param {string} pageNumber      一页显示几个
 * @param {string} orderParam      排序字段 raiseup 点赞 ； visit 人气；collect 收藏；
 * @param {string} sort            排序方式：  desc 降序排列  ;    asc 升序排列
 * @param {string} name            公司名称
 */
async orgCardList(params) {
  return _axios.get(`/tkcms/org/card/list`,{params})
}

/** 
 * @params {string} teamId         团队ID
*/
// 获取团队成员列表
async orgAllMembers(id) {
  return _axios.get(`/tkcms/org/all/members`,{params: {
    teamId:id
  }})
}
// /tkcms/org/my/company
/** 
 * 
*/
// 获取我的企业信息
async orgMyCompany() {
  return _axios.get(`/tkcms/org/my/company`)
}

/** 
 * @params {string} xid           不传xid查询自己的信息，传xid查询别人的名片信息
*/
// 查看名片详情
async orgCardView(id) {
  return _axios.get(`/tkcms/org/card/view`,{params:{
    xid:id
  }})
}
/**
 * 
 * @param {string} province        省份
 * @param {string} city            城市
 * @param {string} district        区
 * @param {string} industryTag     行业 例医疗行业
 * @param {string} pageIndex       页码
 * @param {string} pageNumber      一页显示几个
 * @param {string} orderParam      排序字段 raiseup 点赞 ； visit 人气；collect 收藏；
 * @param {string} sort            排序方式：  desc 降序排列  ;    asc 升序排列
 * @param {string} name            公司名称
 */
//公司名片
async companyCardList(params) {
  return _axios.get(`/tkcms/org/company/card/list`,{params})
}

/** 
 * @param {string} sort            排序方式：  desc 降序排列  ;    asc 升序排列
 * @param {string} orderParam      viewNumber 查看人数；askNumber 咨询人数；
 * @param {string} industry        行业
 * @param {string} name            项目名称或者编号
 * @param {string} flag            我的
*/
//商机中心列表
async businessList(params) {
  return _axios.get(`/tkcms/business/list`,{params})
}
/** 
 * @param {string} id              商机id
*/
//获取商机详情 /tkcms/business/info/:id
async businessInfo(id) {
  return _axios.get(`/tkcms/business/info/${id}`)
 }
//新增商机/tkcms/business/add
/** 
 * @param {string} projectName     项目名称
 * @param {string} projectImg      项目图标
 * @param {string} tag             项目标签
*/
async businessAdd(params) {
  return _axios.post(`/tkcms/business/add`,params)
}
// /tkcms/business/update/:id编辑商机
async businessUpdate(id,params) {
  return _axios.put(`/tkcms/business/update/${id}`,params)
}
//销售列表/tkcms/api/business/owner
async businessOwner(params) {
  return _axios.get(`/tkcms/business/owner`,{params})
 }
 //公司认证列表
 async companyList(params) {
  return _axios.get(`/tkcms/org/companys/list`,{params})
 }
 //修改企业认证通过状态
 /**
  * 
  * @param {string} id        id可以是多个id 
  */
 async companyStatus(id) {
   return _axios.post(`/tkcms/org/company/status`,{
     xid:id,
     status:1
   })
 }
 //修改企业认证拒绝，和添加拒绝原因
//  async updataCompanies(id,params) {
//    return _axios.put(`/tkcms/org/update/companies/${id}`,{},{
//      params:params
//    })
//  }
 async updataCompanies(id,params) {
  return _axios.put(`/tkcms/org/update/companies/${id}`,{},{params})
}
//未申请的企业列表
 async companysApp(params) {
   return _axios.get(`/tkcms/org/companys/app`,{params})
 }

//企业版本列表 
async versionInfo(params) {
  return _axios.get(`/tkcms/version/info`,{params})
}

//新增版本 
// async versionInfoAdd(version,content,) {
//   return _axios.post(`/tkcms/version/info/add`,{
//     content:content,
//     version:version
//   })
// }
async versionInfoAdd(params) {
  return _axios.post(`/tkcms/version/info/add`,params)
}
//删除版本 
// async versionDelete(id) {
//   return _axios.delete(`/tkcms/version/delete/${id}`)
// }

//修改\删除版本信息/api/version/info/update/:id
async versionInfoUpdate(id,params) {
  return _axios.put(`/tkcms/version/info/update/${id}`,params)
}
/**
 * 
 * @param {string} title            查询标题。模糊查询
 * @param {string} startTime        查询开始时间。2021-5-20
 * @param {string} endTime          查询结束时间。模糊查询
 * @param {string} flag             1按照分享次数排序
 * 
 */
async contentAirticleCount (params) {
  return _axios.get(`/tkcms/content/airticle/count`,{params})
}

///tkcms/content/airticle/share/member
/**
 * 
 * @param {string} rid              文章ID
 * @param {string} startTime        开始时间
 * @param {string} endTime          结束时间
 * @param {string} name             员工姓名
 */
async airticleShareMember (params) {
  return _axios.get(`/tkcms/content/airticle/share/member`,{params})
}

// 员工数据 /api/org/all/member/list
/** 
 * @params {string} name            姓名
 * @params {string} teamId          部门
*/
async allMemberList(params) {
  return _axios.get(`/tkcms/org/all/member/list`,{params})
}


// 文章分享明细 /tkcms/org/share/member/detail
/** 
 * @params {string} id              员工id
*/

async shareMemberDetail(params) {
  return _axios.get(`/tkcms/org/share/member/detail`,{params})
}


}

export default new ZGManager();
