import dayjs from 'dayjs'

// 日期格式化
Vue.filter('dateFormat', function(value, format){
  // console.log('value:',value,'format:',format);
  return dayjs(value).format(format || 'YYYY-MM-DD hh:mm:ss')
});

// 格式化文章状态
Vue.filter('articleStatus', function(value){
  switch(value){
    case 0: 
      return '已下架';
    case 1: 
      return '未发布';
    case 2: 
      return '已发布';
    default: return value;
  }
});

// 格式化商品类型
Vue.filter('goodsType', function(val){
  if(val.type == 1 && val.pay_status == 1){
    return "实物-可支付";
  }else if(val.type == 1 && val.pay_status == 2){
    return "实物-仅展示";
  }else if(val.type == 2 && val.pay_status == 1){
    return "虚拟商品";
  }else{
    return "虚拟商品";
  }
});

// 格式化任务目标
Vue.filter('taskgoal', function(val){
  return val.target_task == 0 ? `浏览${val.times}次` : `新增线索${val.times}条`;
});

// 用户来源
Vue.filter('userSource', function(value){
  let text = '未知';
  switch(value){
    case 'card': text = '名片'; break;
    case 'file': text = '文件'; break;
    case 'goods': text = '商品'; break;
    case 'site': text = '官网'; break;
    case 'video': text = '视频'; break;
    case 'article': text = '文章'; break;
  }
  return text;
});