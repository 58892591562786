import Login from "../components/Logon";
let dialog;
export default {
  show() {
    if(!dialog){
      // 创建一个临时div，挂载到body
      let el = document.createElement("div");
      document.body.appendChild(el);
      
      // 实例化一个login组件
      const LoginConstructor = Vue.extend(Login);
      dialog = new LoginConstructor({ el: el });
      Vue.prototype.$message({
        duration: 4000,
        message: "无效的登录信息或您当前的账号没有访问权限，请检查", 
        type: 'error', 
        customClass: 'z-index-999999'
      });
    }
    dialog.$data.dialogvisible = true;
  }
}