const routes = [
  { path: '/index',  
    component: ()=> import('../views/Home'),
    children: [
      // 概况
      { path: '/index', meta: { title: '首页' }, component: ()=> import('../views/Index')},

      // 商城管理
      { path: '/shop/goods/list', meta: { title: '商品管理' }, component: ()=> import('../views/Shop/Goods/Manage'),
        children: [
          { path: '/shop/goods/list', component: ()=> import('../views/Shop/Goods/List')},
          { path: '/shop/goods/sort', component: ()=> import('../views/Shop/Goods/Sort')},
          { path: '/shop/goods/specs', component: ()=> import('../views/Shop/Goods/Specs')},
        ]
      },
      { path: '/shop/goods/add', meta: { title: '添加商品' }, component: ()=> import('../views/Shop/Goods/Add')},
      { path: '/shop/renovation', meta: { title: '商城装修' }, component: ()=> import('../views/Shop/Renovation')},
      { path: '/shop/order/list', meta: { title: '订单管理' }, component: ()=> import('../views/Shop/Order/List')},
      { path: '/shop/order/aftersale', meta: { title: '订单管理' }, component: ()=> import('../views/Shop/Order/Aftersale')},

      // 图库
      // { path: '/source/system/image', meta: { title: '系统图库' }, component: ()=> import('../views/Source/System/Image')},
      // { path: '/source/user/image', meta: { title: '我的图库' }, component: ()=> import('../views/Source/User/Image')},

      // // 礼品中心
      // { path: '/goods/list', meta: { title: '礼品中心' }, component: ()=> import('../views/Goods/List')},

      // 文件夹
      { path: '/folder/list', meta: { title: '文件夹' }, component: ()=> import('../views/Folder/List')},

      // 营销管理
        // 名片装修
      { path: '/card/edit', meta: { title: '名片装修' }, component: ()=> import('../views/Card/Edit')},
        // 文章营销
      { path: '/article/list', meta: { title: '文章营销' }, component: ()=> import('../views/Article/Index'),
        children: [
          { path: '/article/list', component: ()=> import('../views/Article/Library')},
          { path: '/article/data', component: ()=> import('../views/Article/Analyse')},
          { path: '/article/staff', component: ()=> import('../views/Article/Staff')},
        ]
      },
      //查看文章数据
      { path: '/article/view', component: ()=> import('../views/Article/View')},
      { path: '/article/edit', meta: { title: '文章编辑' }, component: ()=> import('../views/Article/Edit')},
        //海报集合
      { path:'/poster/poster', meta: { title: '海报集合'} , component: ()=> import('../views/Poster/Poster.vue')},
        // 获客海报
      { path: '/poster/list', meta: { title: '海报' }, component: ()=> import('../views/Poster/List.vue')},
        // 企业宣传册
      { path: '/brochure/list', meta: { title: '企业宣传册' }, component: ()=> import('../views/Brochure/List.vue')},
        // 案例库
      { path: '/case/list', meta: { title: '案例库' }, component: ()=> import('../views/Case/List')},
        // 任务管理
      { path: '/task/list', meta: { title: '任务管理' }, component: ()=> import('../views/Task/Index'),
        children: [
          { path: '/task/list', component: ()=> import('../views/Task/List')},
          { path: '/task/edit', component: ()=> import('../views/Task/Edit')},
          { path: '/task/chart', component: ()=> import('../views/Task/Chart')},
        ]
      },

      // 客户管理
      { path: '/customer/success',meta: { title: '成交客户' },component: ()=> import('../views/Customer/Success')},
      { path: '/customer/latent',meta: { title: '潜在客户' },component: ()=> import('../views/Customer/Latent')},

      // 员工管理
      { path: '/member/grounp', meta: { title: '团队设置' },component: ()=> import('../views/Member/Grounp')},
      { path: '/member/role', meta: { title: '角色管理' },component: ()=> import('../views/Member/Role')},
      { path: '/member/role/set', meta: { title: '角色成员管理' },  component: ()=> import('../views/Member/RoleMember')},

      // 客户管理
      { path: '/customer/tags', meta: { title: '标签管理' },component: ()=> import('../views/Customer/Tags')},
      { path: '/customer/list', meta: { title: '客户管理' },component: ()=> import('../views/Customer/List')},

      //名片广场
      { path: '/square/index', meta: { title: '名片' },component: ()=> import('../views/Square/Index'), 
      children: [
        {path: '/square/index', component: ()=> import('../views/Square/Plaza')},
        {path: '/square/companycard', component: ()=> import('../views/Square/CompanyCard')},
      ]
    },
     //商机中心
     { path: '/opportunity/index',meta: { title: '商机中心'}, component:()=> import('../views/Opportunity/Index'),
      children:[
        {path: '/opportunity/index',component: ()=> import('../views/Opportunity/List')},
        {path: '/opportunity/mlist',component: ()=> import('../views/Opportunity/MeList')},
      ]
     },

      // 数据分析
      { path: '/analysis/index', meta: { title: '员工数据' },component: ()=> import('../views/Analysis/Index'), 
        children: [
          { path: '/analysis/index', component: ()=> import('../views/Analysis/Suvery')},
          { path: '/analysis/list', component: ()=> import('../views/Analysis/List')},
          { path: '/analysis/detail', component: ()=> import('../views/Analysis/Detail.vue')},
        ]
      },

      // 企业设置
      { path: '/set/company',meta: { title: '企业信息' },component: ()=> import('../views/Setting/Company')},
      //全流量专用
      { path: '/qllcompany/attestation', meta: { title: '企业认证'}, component: ()=> import('../views/QllCompany/Attestation')},
      { path: '/qllcompany/replace', meta: { title: '更新说明'}, component: ()=> import('../views/QllCompany/Replace')},
      //新增和修改版本
      { path: '/qllcompany/add', meta:{ title: '新增更新'}, component: ()=> import('../views/QllCompany/Add')},
      
    ]
  },
  { path: '/', meta: { title: '登录' }, component: ()=> import('../views/Login')},
  { path: '/login', meta: { title: '登录' }, component: ()=> import('../views/Login')},
  { path: '/site/edit', meta: { title: '官网装修' }, component: ()=> import('../views/Site/Edit')},
  { path: '/brochure/edit', meta: { title: '添加宣传册' }, component: ()=> import('../views/Brochure/Add')},
  { path: '/poster/edit', meta: { title: '添加海报' }, component: ()=> import('../views/Poster/Add')},
  { path: '/case/edit', meta: { title: '添加宣传册' }, component: ()=> import('../views/Case/Add')},
  //名片详情
  // { path:'/square/details/:xid',name:'Details',component: ()=> import('../views/Square/Details')},
  { path: '/square/details',name:'Details',component: ()=> import('../views/Square/Details')},
  //增加商机
  { path: '/opportunity/add',component: ()=> import('../views/Opportunity/Add')},
  //编辑商机
  { path: '/opportunity/edit',component: ()=> import('../views/Opportunity/Edit')},
  //未申请的企业
  { path: '/qllcompany/apply',component: ()=> import('../views/QllCompany/Apply')},
  
];

export default routes;