'use strict'

const chars = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "m", "n", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"];

if (typeof Object.assign != 'function') {
  // Must be writable: true, enumerable: false, configurable: true
  Object.defineProperty(Object, "assign", {
    value: function assign(target, varArgs) { // .length of function is 2
      if (target == null) { // TypeError if undefined or null
        throw new TypeError('Cannot convert undefined or null to object');
      }
      let to = Object(target);
      for (var index = 1; index < arguments.length; index++) {
        var nextSource = arguments[index];
        if (nextSource != null) { // Skip over if undefined or null
          for (let nextKey in nextSource) {
            // Avoid bugs when hasOwnProperty is shadowed
            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
              to[nextKey] = nextSource[nextKey];
            }
          }
        }
      }
      return to;
    },
    writable: true,
    configurable: true
  });
}

if (typeof Array.prototype.forEach !='function') {
  Array.prototype.forEach = function (action) {
    if(typeof action != 'function') throw new Error('参数action不是函数');
    for(let i =0; i< this.length; i++) {
      action(this[i], i, this);
    }
  }
}

/**
 * 动态加载远程JS
 * @param {string} src 远程JS文件URL地址
 * @param {number} timeout 加载超时时间。单位毫秒。默认5000毫秒超时
 */
export const loadjs = async function(src, timeout=5000) {
  return new Promise((resolve, reject)=> {
    let target = document.getElementsByTagName('script')[0] || document.head;
    var script = document.createElement("script");
    script.setAttribute("type","text/javascript");
    // script.setAttribute("crossorigin","anonymous");
    let t = setTimeout(()=>{
      console.error("脚本加载超时");
      reject(new Error(`脚本【${src}】加载超时`));
    }, timeout);
    //IE
    script.onreadystatechange = function() {
      console.log("script onreadystatechange");
      if(this.readyState == "loaded" || this.readyState == "complete") {
        clearTimeout(t); 
        resolve();
      }
    };
    //Opera、FF、Chrome等
    script.onload = function() {
      console.log("script onload");
      clearTimeout(t); 
      resolve(); 
    }
    script.setAttribute("src", src); 
    target.parentNode.insertBefore(script, target);
  });
}

/**
 * 将传统callback方法转化为promise方法
 * @param {function} original
 */
export const promisify= function(original) {
  return function (...args) {
    return new Promise((resolve, reject) => {
      if (typeof original != "function") throw Error("original is not function");
      let callback = function (err, ...values) {
        if(values.length===0 && !(err instanceof Error)) return resolve(err);
        if (err) return reject(err);
        values.length === 1 ? resolve(values[0]) : resolve(values);
      };
      args.push(callback);
      // Call the function.
      original.call(this, ...args);
    });
  };
}

/**
 * 获取全局唯一ID
 */
export const getUUID = function () {
  function S4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }
  return (S4() + S4() + S4() + S4() + S4() + S4() + S4() + S4());
}

/**
 * 校验字符串长度是否在指定范围内（一个汉字算两个字符
 * @param {string} val         需要校验的字符串
 * @param {number} min         最小长度
 * @param {number} max         最大长度
 */
export const checkStringLength = function (val, min, max) {
  let chinese, chineseRegx = /[\u4e00-\u9fa5]/g, other, otherRegx = /[^\u4e00-\u9fa5]/g, result;
  chinese  = val.match(chineseRegx) || '';
  other = val.match(otherRegx) || '';
  if(chinese) chinese = chinese.join("");
  if(other) other = other.join("");
  result = chinese + other;
  if(result.length >= min && result.length <= max){
    return true;
  }else{
    return false;
  }
}

/**
 * 判断是否在微信中
 */
export const isWechat = function () {
  let ua = window.navigator.userAgent.toLowerCase();
  return ua.match(/MicroMessenger/i) == "micromessenger";
}

/**
 * 校验手机号
 */
export const checkPhone = function (value) {
  let reg = /^1([3456789])\d{9}$/;
  if (reg.test(value)) {
    return true;
  } else {
    return false;
  }
}

/**
 * 校验手机号复杂版
 */
export const checkPhoneComplex = function (value) {
  let reg = /^1([38][0-9]|4[579]|5[0-3,5-9]|6[6]|7[0135678]|9[189])\d{8}$/;
  if (reg.test(value)) {
    return true;
  } else {
    return false;
  }
}

/**
 * 校验邮箱
 */
export const checkEmail = function (value) {
  let reg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,3}){1,2})$/;
  if (reg.test(value)) {
    return true;
  } else {
    return false;
  }
}

/**
 * 校验车牌号
 */
export const carNumber = function (value) {
  let reg = /^(([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z](([0-9]{5}[DF])|([DF]([A-HJ-NP-Z0-9])[0-9]{4})))|([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z][A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳使领]))$/;
  if (reg.test(value)) {
    return true;
  } else {
    return false;
  }
}

/**
 * 校验网址
 */
export const checkUrl = function (value) {
  let reg = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\*\+,;=.]+$/;
  if (reg.test(value)) {
    return true;
  } else {
    return false;
  }
}

/**
 * 校验信用卡卡号
 */
export const checkCreditCardNo = function (code) {
  if(!code || code.length<14) return false;
  let sum = 0;
  for(let i=1; i<=code.length; i++) {
    if(i % 2 === 0) {
      let n = parseInt(code.charAt(code.length-i), 32) * 2;
      sum += Math.floor((n / 10)) + (n % 10);
    } else {
      sum += parseInt(code.charAt(code.length-i), 32);
    }
  }
  return sum % 10 === 0;
}

/**
 * 生成随机字符串（首位不为数字）
 * @param len
 * @return {string} 数字和字符
 */
export const RandomCode = function (len = 8) {
  let n = chars.length;
  let code = '';
  if (len <= 0) len = 8;
  while (len > 0) {
    let index = code == '' ?
      (10 + parseInt(Math.random() * (n - 10))) :
      parseInt(Math.random() * n);
    code += chars[index];
    len--;
  }
  return code;
};

/**
 * 判断是否Object对象
 * @param {any} obj
 */
export const isObject = function (obj) {
  return Object.prototype.toString.call(obj) === '[object Object]';
}
/**
 * 判断是否数组对象
 * @param {any} obj
 */
export const isArray = function (obj) {
  return Object.prototype.toString.call(obj) === '[object Array]';
}
/**
 * 获取函数的形式参数列表
 */
export const getFunArgs = function(fn) {
  if(typeof fn !== 'object' && typeof fn !== 'function' ) throw new Error('fn不是函数对象');
  let r = /[^(]*\(([^)]*)\).*/.exec(fn.toString());
  if(!r[1] || !r[1].trim()) return [];
  return r[1].split(',').map(a=>a.trim());
}

/**
 * 将querystring对象化
 * @param {string} querystring
 */
export const qs = function(querystring) {
  if(!querystring) return {};
  querystring = querystring.replace(/(?:^.*\?)?(.*)/, '$1');
  return querystring.trim().split('&').reduce((s, item) => {
    let kv = item.split('=');
    kv[0].trim() && (s[kv[0].trim()] = decodeURIComponent(kv[1]||''));
    return s;
  }, {});
}

/**
 * 获取query参数值
 * @param {string} name 参数名
 */
export const getQueryParam = function (name) {
  var reg = new RegExp("[?&]" + name + "(=[^&#]*)?(?:&|$|#)");
  var r = window.location.href.match(reg); //search,查询？后面的参数，并匹配正则
  if (r == null) return null;
  return !!r[1] ? decodeURIComponent(r[1].replace(/^=/, "")) : '';
}
/**
 * 向url地址中添加query请求参数
 * @param {string} url
 * @param {string} name
 * @param {string} value
 */
export const addQueryParam = function (url, name, value) {
  url = url || '/';
  url += url.indexOf("?") === -1 ? "?" : "&";
  url += name;
  if (value) url += "=" + encodeURIComponent(value);
  return url;
}

/**
 * 获取/设置cookie
 * @param {string} name cookie名
 * @param {string} value 要设置的cookie值
 * @param {object} options 可选配置项 domain、path、expried等
 */
export const cookie = (name, value, options) => {
  if (value != undefined) {
    // name and value given, set cookie
    options = options || {};
    if (value === null) {
      value = "";
      options.expires = -1;
    }
    var expires = "";
    if (options.expires && (typeof options.expires == "number" || options.expires.toUTCString)) {
      var date;
      if (typeof options.expires == "number") {
        date = new Date();
        date.setTime(date.getTime() + options.expires * 24 * 60 * 60 * 1000);
      } else {
        date = options.expires;
      }
      expires = "; expires=" + date.toUTCString(); // use expires attribute, max-age is not supported by IE
    }
    var path = options.path ? "; path=" + options.path : "";
    var domain = options.domain ? "; domain=" + options.domain : "";
    var secure = options.secure ? "; secure" : "";
    document.cookie = [
      name,
      "=",
      encodeURIComponent(value),
      expires,
      path,
      domain,
      secure
    ].join("");
  } else {
    // only name given, get cookie
    if (document.cookie) {
      var cookies = document.cookie.split(";");
      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i].replace(/^\s+|\s+$/gm, '');
        // Does this cookie string begin with the name we want?
        if (cookie.substring(0, name.length + 1) == name + "=") {
          value = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return value || '';
  }
};

/**
 * 事件防抖
 * @param {Function} func 执行函数
 * @param {Number} delay 延时执行时间。在延时间隔内即使多次调用也保证事件只执行一次
 */
export const debounce = function (func, delay) {
  let timeout;
  return function () {
    let context = this;
    let args = arguments;
    let later = function () {
      timeout = null;
      func.apply(context, args);
    };
    timeout && clearTimeout(timeout);
    timeout = setTimeout(later, delay);
  };
}
/**
 * 事件轮询
 * @param {Function} fn 执行函数。以函数返回值的true/false确定回调
 * @param {Function} fn_callback 成功回调
 * @param {Function} fn_timeout 超时回调
 * @param {Number} timeout 超时时间
 * @param {Number} interval 轮询间隔
 */
export const poll = function (fn, fn_callback, fn_timeout, timeout, interval) {
  var end_time = Number(new Date()) + (timeout || 2000);
  interval = interval || 100;
  (function p() {
    // 如果条件满足，则执行！
    if (fn()) fn_callback();
    // 如果条件不满足，但并未超时，再来
    else if (Number(new Date()) < end_time) setTimeout(p, interval);
    // 不匹配且时间消耗过长，则拒绝！
    else fn_timeout(new Error('timed out for ' + fn + ': ' + arguments));
  })();
}
/**
 * 保证函数只执行一次
 * @param {Function} fn 执行函数
 * @param {Object} context 执行上下文，可空
 */
export const once = function (fn, context) {
  var result;
  return function () {
    if (fn) {
      result = fn.apply(context || this, arguments);
      fn = null;
    }
    return result;
  };
}
/**
 * 函数衰变执行
 * @param {function} fn 执行函数, 明确返回false, 则终止执行
 * @param {Number} ms 初始执行间隔（毫秒）
 * @param {Number} k 衰变系数。 取值区间（0, ∞)；等于1时，不衰变，等价于setInterval
 * @param {Number} ultimate 执行间隔极限值。k=1或k<=0时无效
 */
export const decay = function (fn, ms, k = 1, ultimate) {
  if (ms <= 0) throw new Error('ms 参数必须大于0');
  if (k === 1 || k <= 0) {
    //立即执行1次
    let r = fn(ms);
    if (r === false || ms <= 0) return;
    let t = setInterval(() => {
      if (false === fn(ms)) clearInterval(t);
    }, ms);
  } else {
    let next = ms * k;
    if (ultimate > 0 && ((k < 1 && next <= ultimate) || (k > 1 && next >= ultimate))) {
      decay(fn, ultimate, 1);
    } else {
      let r = fn(next);
      if (false === r) return;
      let t = setTimeout(() => {
        clearTimeout(t);
        decay(fn, next, k, ultimate);
      }, next);
    }
  }
}

/**
 * 等待xx毫秒
 * @param {Number} ms 等待毫秒数
 */
export const waiting = async function (ms) {
  return new Promise((resolve, reject) => {
    let t = setTimeout(() => {
      clearTimeout(t);
      resolve();
    }, ms);
  });
}

/**
 * 深度对象合并
 * 合并原则：
 *  相同属性名且数组类型将合并成员
 *  相同属性名且不同类型的将被覆盖
 * @param {Object} to 合并目标对象
 * @param {Object} from 合并源对象
 */
export const merge = function (to, from) {
  if (!to || to.constructor != Object) return from;
  for (var key in from) {
    if (Array.isArray(from[key]) && Array.isArray(to[key])) {
      to[key] = to[key].concat(from[key]);
    } else if (from[key].constructor === Object && to[key].constructor === Object) {
      o[key] = merge(to[key], from[key]);
    } else {
      to[key] = from[key];
    }
  }
  return to;
}
/**
 * 获取/设置对象属性值
 * @param {Object} obj 目标对象
 * @param {string} path 属性路径。eg. a.b.c
 * @param {string} value 设置值。不传则为获取属性值
 */
export const objectProperty = function (obj, path, value) {
  let props = path.split(".");
  let last = props.pop();
  for (var i = 0; i < props.length; i++) {
    var p = props[i];
    if (obj && obj.hasOwnProperty(p)) {
      obj = obj[p];
    } else {
      return undefined;
    }
  }
  return value === undefined ? obj[last] : (obj[last] = value);
}

/**
 * padLeft
 * @param {string} s
 * @param {integer} len
 * @param {string} char
 */
export const padLeft = function (s, len, char = '0') {
  var len = len - (s + '').length;
  for (var i = 0; i < len; i++) {
    s = char + s;
  }
  return s;
};

/**
 * padRight
 * @param {string} s
 * @param {integer} len
 * @param {string} char
 */
export const padRight = function (s, len, char = '0') {
  var len = len - (s + '').length;
  for (var i = 0; i < len; i++) {
    s += char;
  }
  return s;
};

// #region datetime
var SIGN_REGEXP = /([YMDhsm])(\1*)/g;
var DEFAULT_PATTERN = 'YYYY-MM-DD hh:mm:ss'

/**
 * 日期时间对象格式化
 * @param {时间} date
 * @param {*} pattern 如：YYYY-MM-DD hh:mm:ss
 */
export const dateFormat = function (date, pattern) {
  if (!(date instanceof Date)) date = new Date(date);
  pattern = pattern || DEFAULT_PATTERN;
  return pattern.replace(SIGN_REGEXP, function ($0) {
    switch ($0.charAt(0)) {
      case 'Y':
        return padLeft(date.getFullYear(), $0.length);
      case 'M':
        return padLeft(date.getMonth() + 1, $0.length);
      case 'D':
        return padLeft(date.getDate(), $0.length);
      case 'w':
        return date.getDay() + 1;
      case 'h':
        return padLeft(date.getHours(), $0.length);
      case 'm':
        return padLeft(date.getMinutes(), $0.length);
      case 's':
        return padLeft(date.getSeconds(), $0.length);
    }
  });
}
/**
 * 日期时间字符串解析
 * @param {*} dateStr
 * @param {*} pattern 如：YYYY-MM-DD hh:mm:ss
 */
export const dateParse = function (dateStr, pattern) {
  var matchs1 = pattern.match(SIGN_REGEXP);
  var matchs2 = dateStr.match(/(\d)+/g);
  if (matchs1.length == matchs2.length) {
    var _date = new Date(1970, 0, 1);
    for (var i = 0; i < matchs1.length; i++) {
      var _int = parseInt(matchs2[i]);
      var sign = matchs1[i];
      switch (sign.charAt(0)) {
        case 'Y':
          _date.setFullYear(_int);
          break;
        case 'M':
          _date.setMonth(_int - 1);
          break;
        case 'D':
          _date.setDate(_int);
          break;
        case 'h':
          _date.setHours(_int);
          break;
        case 'm':
          _date.setMinutes(_int);
          break;
        case 's':
          _date.setSeconds(_int);
          break;
      }
    }
    return _date;
  }
  return null;
}
// #endregion

/**
 * 获取/设置sessionStorage
 * @param {number} type    get获取，set设置
 * @param {string} name    设置的sessionStorage的名称
 * @param {object} value   sessionStorage的值
 * @deprecated 废弃，建议使用下面的sessionStorage 方法
 */
export const session = function (type, name, value) {
  if (type == 'get') {
    if (window.sessionStorage.getItem(name) && window.sessionStorage.getItem(name).length > 0) {
      return JSON.parse(window.sessionStorage.getItem(name));
    } else {
      return false;
    }
  }
  if (type == 'set') {
    return window.sessionStorage.setItem(name, JSON.stringify(value));
  }
}

/**
 * 获取/设置sessionStorage
 */
export const sessionStorage = function(name, value) {
  if (value != undefined) {
    // set
    value ? window.sessionStorage.setItem(name, JSON.stringify(value)) : window.sessionStorage.removeItem(name);
  } else {
    // get
    let v = window.sessionStorage.getItem(name);
    return v ? JSON.parse(v) : '';
  }
}

/**
 * 获取/设置localStorage
 */
export const localStorage = function(name, value) {
  if (value != undefined) {
    // set
    value ? window.localStorage.setItem(name, JSON.stringify(value)) : window.localStorage.removeItem(name);
  } else {
    // get
    let v = window.localStorage.getItem(name);
    return v ? JSON.parse(v) : '';
  }
}

export const getUrlKey = function (name, url) {
  return ((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(decodeURIComponent(url)) || [, ""])[1].replace(/\+/g, '%20')) || null
}

/**
   * 跳转结算
   * @param {string} redirect_url 支付回调
   * @param {string} channel 支付通道标识
   * @param {string} business_id 业务id
   * @param {Number} phoneNumber 业务id
   * @param {Number} quantity 数量
   * @param {string} order_type 订单类型
   * @param {string} business_type 业务类型
   * @param {string} code 产品编码
   * @param {string} price 产品价格
   * @param {string} name 产品名称
   * @param {string} imgUrl 产品Icon
   * @param {Boolean} header 结算页是否有头部 1没有 其他有
   * @param {Number} order_flag 订单标志类型
   *
   */
export const toSettlement = function (params) {
  let settlement = {
    business_id: params.business_id,
    channel: params.channel,
    redirect_url: params.redirect_url,
    phone_number: params.phoneNumber,
    product: [
      {
        id: getUUID(),
        type: params.order_type,
        b_type: params.business_type,
        product_code: params.code,
        quantity: params.quantity,
        product_name: params.name,
        img: params.imgUrl,
        channel: params.channel,
        order_flag:params.order_flag,
      }
    ]
  };
  window.localStorage.setItem("settlement_info", JSON.stringify(settlement));
  window.location.href = "/settlement?id1=" + settlement.product[0].id + `&header=${params.header}`;
}
//#endregion
    /**
 * 请求名片的资源
 * @param {*} query             请求类型。bg为请求背景，icon为请求图片
 * @param {*} type              名片类型
 * @param {*} number            请求的背景序号或者icon类型
 * @param {*} color             请求的icon的颜色，当queryType为icon时启用，可能的值为white, black     
 */
export const cardResource = function (query, type, number, color) {
  if(type == 2){
    let color = ''
    let a = parseInt(number)
    switch(a){
      case 1: color = '#0091FF'; break;
      case 2: color = '#44D7B6'; break;
      case 3: color = '#6236FF'; break;
      case 4: color = '#32C5FF'; break;
      case 5: color = '#6DD400'; break;
      case 6: color = '#F7B500'; break;
      case 7: color = '#FA6400'; break;
      case 8: color = '#E02020'; break;
      case 9: color = '#333333'; break;
      default: color = '#CED4E0'; 
    }
    return color
  }
  if(query == 'bg'){
    return `https://img0.qll-times.com/cdn/card/card/style-resource-${type}/bg${number}.png?v=2`
  }else if(query == 'icon'){
    return `https://img0.qll-times.com/cdn/card/card/style-resource-${type}/${color}-icon-${number}.png?v=2`
  }
}
export const backArraya = function(str) {
  return str.split(',')
}
