<template>
  <el-dialog 
    width="400px"
    custom-class="zg-dialog"
    :center="true"
    :lock-scroll="true"
    :show-close="false"
    :close-on-press-escap="false"
    :close-on-click-modal="false"
    :modal="dialogIsModel"
    :visible="dialogvisible">

    <div slot="title" class="logo">
      <img src="https://image.qll-times.com/2021/04/e08a661764ea49c6a03d5278aa74283e.png" alt="商机圈子平台">
      商机圈子管理平台
    </div>

    <div class="input">
      <el-form>
        <el-input v-model="name" minlength="8" maxlength="20"
          prefix-icon="el-icon-user" placeholder="用户名"
          @keyup.enter.native="signIn"></el-input>
        <el-input v-model="password" minlength="8" maxlength="20" style="ime-mode:disabled;"
          show-password prefix-icon="el-icon-unlock" placeholder="密码"
          @keyup.enter.native="signIn"></el-input>
      </el-form>
    </div>

    <span class="dialog-footer">
      <el-button type="primary" @click="signIn">登录</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { cookie, session } from '@/js/Utils';
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isModal: {
      type: Boolean,
      default: true
    }
  },
  data(){
    return {
      name: "",
      password: "",
      dialogvisible: this.visible,
      dialogIsModel: this.isModal
    }
  },
  methods: {
    async signIn(){
      if(this.name.length < 8 || this.name.length > 20 || this.password.length < 8 || this.password.length > 20){
        return this.$message.error("用户名和密码的长度为8-20位，请检查后重新输入");
      }
      let res = await this.$ZGManager.userLogin(this.name, this.password);
      // console.log(res);
      if(res.status == 200 && res.data && res.data.token){
        cookie('token', res.data.token, {
          expires: 1,
          path: '/'
        });
        this.$EventBus.$emit("accountChange");
        this.$localStorage("ZGCMS_ACCOUNT", res.data.account);
        let tmp = document.location.hash
        if(tmp == "" || tmp == "#" || tmp == "#/" || tmp == "#/login"){
          window.location.replace('/cms/#/index');
        }else{
          window.location.reload();
        }
        this.dialogvisible = false;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.logo{
  text-align: center;
  line-height: 30px;
  font-size: 20px;
  img{
    display: inline-block;
    height: 30px;
    vertical-align: sub;
  }
}
.input{
  ::v-deep .el-input{
    margin-bottom: 30px;
  }
}
.dialog-footer{
  ::v-deep .el-button{
    width: 100%;
  }
}
</style>